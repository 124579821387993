import React from "react"
import { makeStyles } from "@material-ui/core"
import Layout from "../components/layout"
import { graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
  iframevideo: {
    position: "absolute",
    top: "0%",
    left: "0%",
    width: "100%",
    minHeight: "100%",
    border: 0,
    backgroundColor: "#7769927a",
    padding: 15,
    borderRadius: 1,
  },
}))

const Back = ({ data }) => {
  const classes = useStyles()
  return (
    <Layout>
      <iframe
        className={classes.iframevideo}
        // src={"https://cargador-eventos.herokuapp.com/expositores"}
        src={data.strapiConfiguracion.backend_link}
        title="Backend"
        //  allow="accelerometer; autoPlay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        allow="autoplay"
      />
    </Layout>
  )
}

export default Back

export const query = graphql`
  query {
    strapiConfiguracion {
      backend_link
    }
  }
`
